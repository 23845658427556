//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KeyPin from "@/components/KeyPin";
import UserAvatar from "@/components/User/UserAvatar";
1
import { mapActions } from "vuex";

const defaultImage = require("@/assets/images/no-user.jpg");

export default {
  name: "UserPinForm",
  props: {
    subtitle: { type: String, default: "Votre code personnel" },
    buttonText: { type: String, default: "Continuer" },
    link: {
      type: Object,
      default: () => ({
        text: "Mot de passe oublié ?",
        to: "/reset-password",
      }),
    },
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      firstname: this.user.prenom,
      keyCode: "",
      passwordNeedSet: this.user.passwordNeedSet,
      image: this.user.image,
      defaultImage: defaultImage,
      success: false,
    };
  },
  components: {
    KeyPin,
    UserAvatar,
  },
  computed: {
    title() {
      return `Bonjour ${this.firstname || ""} !`;
    },
  },
  methods: {
    ...mapActions("utilisateur", ["getUsers"]),
    async handleSubmit() {
      await this.$emit("submit", this.keyCode);
      this.keyCode = "";
    },
    isCompletePin(e, pin) {
      this.keyCode = pin;
      this.success = e;
    },
  },
};
