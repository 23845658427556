//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Notification from "~/components/Notification";
import AuthLogo from "~/components/Auth/AuthLogo";
import selectUser from "~/components/Auth/select-user";
import userPin from "~/components/Auth/userPin";
import { mapActions, mapState } from "vuex";
import ordering from "@/mixins/ordering";
import global from "@/mixins/global";
import loader from "@/components/common/loader";
import LoginSvg from "@/components/common/LoginSvg";
import CompanyChoice from "@/components/login/CompanyChoice.vue"
import * as Sentry from "@sentry/browser";
export default {
  fetchOnServer: false,
  auth: false,
  layout: "anonymous",
  head() {
    return {
      title: this.$fetchState.pending ? "Chargement..." : "Connexion",
    }
  },
  mixins: [ordering, global],
  components: {
    Notification,
    LoginSvg,
    AuthLogo,
    selectUser,
    userPin,
    loader,
    CompanyChoice
  },
  data() {
    return {
      connectMode: "userpin",
      message: "",
      selectedUser: null,
      email: "",
      password: "",
      error: null,
      inprogess: false,
      selectAccount: false,
      selected: null,
      local_users: undefined,
      entreprises: [],
      showEntreprises: false
    };
  },

  computed: {
    ...mapState('utilisateur', ['users']),
    isNewConnect() {
      return !(this.local_users && this.local_users.data && this.local_users.data.length > 0)
    },
    async formIsValid() {
      return await this.$refs.loginForm?.validateWithInfo()?.isValid;
    },
    showPin() {
      return this.selectedUser && !this.$fetchState.pending
    },
    showSelectUser() {
      return (!this.isNewConnect && this.connectMode === 'userpin') && (!this.selectedUser && !this.$fetchState.pending)
    },
    showForm() {
      return (this.isNewConnect || this.connectMode === 'userpassword') && !this.$fetchState.pending
    }
  },
  async fetch() {
    if (localStorage) {
      const _ = localStorage.getItem('users');
      const lastuser = localStorage.getItem("lastuser");
      if (_ && lastuser) {

        this.local_users = JSON.parse(_);

        this.local_users.data = this.local_users.data.filter(d => d.id == JSON.parse(lastuser));
      } else {
        this.local_users = undefined;
      }
    }
  },

  methods: {
    ...mapActions('utilisateur', ['getUsers', 'setFilter']),
    ...mapActions('settings', ['getSettings']),
    async handlePinSubmit(val) {
      this.email = val.username;
      this.password = val.password;
      await this.login();
    },

    async connexionsuccess() {
      this.inprogess = true;
      this.message = "Initialisation en cours";
      await this.refresh();
      await this.$router.push("/");

    },
    async refresh() {
      this.setFilter({ text: null })
      const users = await this.getUsers({ perPage: -1, page: -1 })
      localStorage.setItem("users", JSON.stringify(users))
      await this.getSettings();
    },
    handleClick() {
      this.connectMode = 'userpin'
      this.$fetch();
    },
    updateSelected(value) {
      this.selectedUser = value
    },
    async login() {
      if (await this.formIsValid) {
        return this.$errorNotification("Message d'erreur", 'Une ou plusieurs erreurs sont présentes dans le formulaire')
      }
      try {
        this.inprogess = true;
        this.message = "Connexion en cours";

        await this.$auth
          .loginWith("local", {
            data: {
              username: this.email,
              password: this.password
            },
          })
          .then(
            async (x) => {
              localStorage.setItem("lastuser", JSON.stringify(x.data.userId))
              if (x.data.passwordNeedSet) {
                const token = x.data.passwordResetToken;
                const userId = x.data.userId;

                if (token && userId) {
                  const url = `/create-password/${userId}`
                  await this.$router.push({ path: url, query: { token: token } });
                  return;
                }
              }
              if (x.data.entreprises && x.data.entreprises.length > 1) {
                this.entreprises = x.data.entreprises;
                this.showEntreprises = true;
                return;
              }

              this.inprogess = true;
              this.message = "Initialisation en cours";
         
              await this.refresh()


              await this.$router.push("/");
              this.$successNotification('Message de validation', 'Connexion réussie, bienvenue 😃')
            },
            (error) => {
              this.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la connexion')
              console.error(error);
            }
          );
      } catch (r) {
        this.$errorNotification("Message d'erreur", 'Une erreur est survenue lors de la connexion')

      } finally {
        this.inprogess = false;
      }
    },
  },
};
