//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserPinCard from "~/components/Auth/UserPinCard";
import Pagination from "@/components/Pagination";
import loader from "@/components/common/loader";
import SearchBar from "@/components/Inputs/SearchBar";
import LoginSvg from "@/components/common/LoginSvg";
import NotFound from "@/components/common/NotFound";


export default {
  auth: false,
  layout: "anonymous",
  name: "selectUser",
  components: {
    UserPinCard,
    Pagination,
    loader,
    SearchBar,
    LoginSvg,
    NotFound
  },
  props: {
    users: {
      type: Object,
      default: () => {
        return {totalCount: 0, data: []}
      }
    },
  },
  fetchOnServer: false,
  async fetch() {

  },

  data() {
    return {
      perPage: 9,
      currentPage: 1,
      search: "",
      total: 0,
    }
  },
  computed: {
    showPagination(){
      if (this.search !== "") return this.filteredUsers.length > this.perPage
      return this.filteredUsers.length !== 0 && this.users.data.length > this.perPage
    },
    filteredUsers() {
      const fields = ['nom', 'prenom', 'userName', 'email'];
      const searchRes = [];
      fields.map(field => {
        this.users.data.filter(el => {
          let elField = el[field] ? el[field] : '';
          elField = elField.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
          if (elField.toLowerCase().includes(this.search.toLowerCase()) && !searchRes.includes(el)) {
            searchRes.push(el)
          }
        })
      })
      this.total = searchRes.length;
      return searchRes.slice(this.perPage * (this.currentPage - 1), (this.perPage * this.currentPage))
    },
    totalCount(){
      if (this.search.length === 0) return this.users.totalCount
      return this.total
    }
  },
  methods: {
    sendSelected(name) {
      this.$emit('selected', name)
    },
  }
}
