// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/arrow-right-icon.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app__btn--right[data-v-fbc73068]{margin-right:8px;transition:margin .8s ease}.app__btn--right[data-v-fbc73068]:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");content:\"\";width:16px;height:10px;margin-left:6px;display:inline-block}.forgot[data-v-fbc73068]{font-family:\"Open Sans\",\"Open Sans Semibold\",\"roboto\",serif;font-weight:500;letter-spacing:0;text-decoration:none;display:block;text-align:center;margin-top:24px;font-size:14px}.app__btn--secondary[data-v-fbc73068]{background:#3b51d5;color:#fff;min-width:220px;cursor:pointer;padding:22px 55px;font-size:20px;font-weight:600;line-height:18px;text-align:center;border:0;border-radius:27px}.app__btn--secondary[disabled][data-v-fbc73068]{cursor:default;background:#a5a5a5;color:#fff}.app__btn--secondary[data-v-fbc73068]:not([disabled]):active,.app__btn--secondary[data-v-fbc73068]:not([disabled]):hover{background-color:#232884}.app__subtitle[data-v-fbc73068],.app__title[data-v-fbc73068]{color:#232884;display:block;font-weight:700;line-height:24px;margin:0 0 10px;text-align:center}.app__title[data-v-fbc73068]{font-size:32px;margin-bottom:20px}.app__subtitle[data-v-fbc73068]{font-size:20px}.pin__group[data-v-fbc73068]{margin-top:50px}@media(max-height:1000px){.identity__group[data-v-fbc73068]{display:none}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
