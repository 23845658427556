//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const noUserIcon = require("@/assets/images/no-user.jpg")


export default {
  name: "UserPinCard",
  props: {
    user: {type: Object, default: {}},
    image: {type: String, default: noUserIcon}
  },
  methods: {
    selectUser(){
      this.$emit('selected', this.user)
    }
  }
}
