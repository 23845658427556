//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "NotFound",
  props: {
    value: {type: String, default: null},
    height: {type: String, default: null},
    width: {type: String, default: null},
    text: {type: String, default: 'Aucun résultat'},
    notFound: {type: Boolean, default: false},
    iconSize: {type: Number, default: 40},
  }
}
