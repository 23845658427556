import {mapActions} from "vuex";

export default {
  data() {
    return {
      sort: {OrderBy: '', OrderAsc: false},
      OrderBy: '',
      OrderAsc: false,
      sortDirection: 'asc',
      pageViews: [10, 20, 30],
      perPage: 10,
      page: 1
    }
  },
  computed: {
  },
  methods: {
    async onFiltered() {

    },
    resultPerPage(page){
      this.perPage = page
      if (this.refresh){
        this.refresh()
      }
    },
  },
  watch: {
    sort: {
      deep: true,
      handler(newValue) {

      }
    },
    OrderBy(newValue) {
      this.setOrder({orderBy: newValue})
      this.refresh();
    },

    OrderAsc(newValue) {
      this.setOrder({orderAsc: newValue})
      this.refresh();
    },
    page(newValue){
      if (this.refresh) {
        this.refresh();
      }
    }
  }
}
