//
//
//
//
//
//
//

const noUserIcon = require("@/assets/images/no-user.jpg")

export default {
  name: "UserAvatar",
  props: {
    image: {
      type: String,
      default: noUserIcon
    }
  },
}
