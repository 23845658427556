import moment from "moment";

export default {
  data() {
    return {}
  },
  methods: {
    handleSearch(value, old, key) {
      const search = value.toLowerCase().trim();
      if (!search) return old;
      return old.filter(c => c[key].toLowerCase().indexOf(search) > -1);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    floatToString(val, index) {
      if (!val && val !== 0)
        return val
        if (index==0) {
        return Math.trunc(val)
        }

      if (index==1) {
        return "0"+parseInt(Number.parseFloat("0."+val.toString().split('.')[index])*60).toString().substr(2)
      }

    },
    onlyNumber(e) {
      let keyCode = (e.keyCode ? e.keyCode : e.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        e.preventDefault();
      }
    },
    setCharAt(str, index, chr) {
      return str.substring(0, index) + chr + str.substring(index + 1);
    },
    containNum(val) {
      return /\d/.test(val)
    },
    weekNumOfMonth(m) {
      const list = [];
      const start = moment(m, "MM-DD-YYYY").startOf('month').week();
      const end = moment(m, "MM-DD-YYYY").endOf('month').week();
      for (let i = start; i <= end; i++){
        list.push(i)
      }
      return list;
    }
  },
  computed: {
    /*Pagination*/
    takePage() {
      return (this.page - 1) * this.perPage
    },
    pageInfo() {
      const n = this.perPage > this.totalCount ? this.totalCount : this.takePage + this.perPage;
      return `${this.takePage + 1}-${n} sur ${this.totalCount} résultats`
    }
  }
}
