//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Pagination",
  props: {
    rows: Number,
    perPage: Number,
  },
  data() {
    return {
      currentPage: 1
    }
  }
}
