//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SearchBar",
  props: {
    showButton: {type: Boolean, default: false},
    placeholder: {type: String, default: 'Rechercher...'},
    value : {type: String, default: ''}
  },
  data() {
    return  {
      search: this.value ? this.value : '',
      isFocus: false
    }
  }
}
