//
//
//
//
//
//

export default {
  name: 'Notification',
  props: ['message']
}
