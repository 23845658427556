//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "KeyPin",
  props: {
    value: {type: String, default: ''}
  },
  data() {
    return {
      pinCode: this.value,
      dotsNumber: 6,
    }
  },
  computed: {
    fullPin(){
      return this.pinCode.length === this.dotsNumber
    }
  },
  watch: {
    value(newValue){
      this.pinCode = newValue
    }
  },
  methods: {
    resetPin() {
      this.pinCode = '';
      this.sendParent()
    },
    handleAddPin(num){
      this.pinCode += num;
      this.sendParent()
    },
    sendParent(){
      this.$emit('isCompletePin', this.fullPin, this.pinCode)
    }
  }
}
