//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserPinForm from "@/components/Auth/UserPinForm";

export default {
  name: "userPin",
  auth: false,
  layout: "anonymous",
  props: {
    user: {type: Object, default: {}}
  },
  data() {
    return {
      success: true,
    }
  },
  computed: {
    validation() {
      return false;
    }
  },
  methods: {
    async handleSubmit(val){
      await this.$emit("submit", {
        username: this.user?.userName,
        password: val
      })
    }
  },
  components: {
    UserPinForm
  }
}
